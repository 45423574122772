import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background: #FFF;
    font-family: 'Courier New', Courier, monospace;
  }
`;

const Main = styled.main`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Text = styled.a`
  font-size: 18px;
  letter-spacing: -0.1em;
  text-decoration: none;
  color: #000;
`;

export default () => {
  return (
    <>
      <GlobalStyle />
      <Main>
        <title>Blasko</title>
        <Text href="mailto:contact@blasko.io">Blasko.</Text>
      </Main>
    </>
  );
};
